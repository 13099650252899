import { genAIService } from '@mpx-sdk/api-client';
import { genaiFirebase } from '@mpx-sdk/helpers/assets/genAI';
import { type MLTypes, databases } from '@mpx-sdk/shared/configs';
import { getAuth } from 'firebase/auth';
import { type CollectionReference, Timestamp, collection, doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

export interface BaseFirebaseModel {
	/**
	 * When the document was created
	 * If not provided, it will be set to the current time
	 * @type {Timestamp}
	 */
	createdAt?: Date;

	/**
	 * When the document was updated
	 * If not provided, it will be set to the current time
	 * @type {Timestamp}
	 */
	updatedAt?: Date;

	/**
	 * The user id of the user who created the document
	 * If not provided, it will be set to the current logged in user id
	 * @type {string}
	 */
	userId?: string;

	expireAt?: Timestamp | null;

	status?: 'pending' | 'processing' | 'complete' | 'error';

	sessionId?: string;

	bucket?: string;

	sessionStoragePath?: string;
}

type MLRequestTypes = {
	type: MLTypes;
	data: any;
	projectTitle: string;
};

class MLProcessingDoc {
	docData: BaseFirebaseModel & MLRequestTypes;

	database: CollectionReference;

	sessionsDatabase: CollectionReference;

	// TODO: PROTOSTAGE: Remove genaiFirebase

	constructor(docData: BaseFirebaseModel & MLRequestTypes, session: any) {
		this.database = collection(getFirestore(genaiFirebase), databases.mlRequest);
		this.sessionsDatabase = collection(getFirestore(genaiFirebase), databases.mlSession);
		this.docData = {
			sessionId: session?.id,
			sessionStoragePath: session?.storageRoot || '',
			createdAt: Timestamp.now().toDate(),
			updatedAt: Timestamp.now().toDate(),
			status: 'pending',
			userId: getAuth(genaiFirebase)?.currentUser?.uid ?? 'anonymous',
			expireAt: null,
			bucket: getStorage(genaiFirebase).app.options.storageBucket,
			...docData,
		};
	}

	async save() {
		if (this.docData.sessionId) {
			await setDoc(doc(this.sessionsDatabase, this.docData.sessionId), {
				createdAt: this.docData.createdAt,
				updatedAt: this.docData.updatedAt,
				userId: this.docData.userId,
				expireAt: this.docData.expireAt,
				status: 'active',
				sessionStorageRoot: this.docData.sessionStoragePath,
				bucket: this.docData.bucket,
			});
		}

		const response = await genAIService.generate(this.docData);

		const docRef = await getDoc(doc(getFirestore(genaiFirebase), response.reference));

		return docRef;
	}
}

export default MLProcessingDoc;
