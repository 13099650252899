import { genAIService } from '@api-client/index';
import { getMyBalance } from '@mpx-sdk/helpers/economy';
import { validators } from '@mpx-sdk/helpers/validators';
import { CreditsIcon, RemixAIIcon } from '@mpx-sdk/images';
import { activeStepAtom, inAppBrowserAtom, userAtom } from '@mpx-sdk/shared/atoms';
import { urls } from '@mpx-sdk/shared/configs';
import { GenModelConsts, StepNames } from '@mpx-sdk/types';
import { useDialogService } from '@mpx-sdk/ui/components';
import FormField from '@mpx-sdk/ui/components/core/form/FormField';
import BuyMoreCredit from '@mpx-sdk/ui/components/purchase/BuyMoreCredit';
import {
	AddCircleOutline as AddCircleOutlineIcon,
	RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, IconButton, Slider, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import ResultsStep from './steps/ResultsStep';

function OnePromptTextTo3D() {
	const currentUser = useAtomValue(userAtom);
	const router = useRouter();
	const inApp = useAtomValue(inAppBrowserAtom);
	const { showDialog } = useDialogService();
	const [activeStep, setActiveStep] = useAtom(activeStepAtom);
	const [promptBeforeLogIn, setPromptBeforeLogIn] = useState<string | null>(null);

	useEffect(() => {
		const preLogInPrompt = localStorage.getItem('promptBeforeLogIn');
		if (preLogInPrompt) {
			setPromptBeforeLogIn(preLogInPrompt);
			localStorage.removeItem('promptBeforeLogIn');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleGenerate = async (values: any) => {
		try {
			await genAIService.generateWithAI(values);
			getMyBalance({
				waitForNewBalance: true,
			}); // Update the balance asynchronously
			setActiveStep(StepNames.RESULTS);
			setPromptBeforeLogIn(null);
		} catch (err: any) {
			if (err?.cause?.status === 402) {
				showDialog(BuyMoreCredit.getAsDialog());
			} else {
				console.error('Error while generating', err);
				toast.error('There was an error generating your model(s). If this persists, please contact support.');
			}
		}
	};

	return (
		<div>
			<Form
				initialValues={{
					modelsToGenerate: 2,
					prompt: promptBeforeLogIn,
				}}
				onSubmit={handleGenerate}
			>
				{({ handleSubmit, submitting, form }) => (
					<>
						{activeStep !== StepNames.RESULTS && (
							<Stack
								component='form'
								onSubmit={handleSubmit}
								spacing={2}
								sx={{ maxWidth: 'md', mx: 'auto', p: 2 }}
							>
								<FormField
									fullWidth
									hideCharacterCount
									interactive
									maxWords={5}
									multiline
									name='prompt'
									placeholder={GenModelConsts.promptPlaceholder}
									rows={4}
									title={GenModelConsts.promptLabel}
									validators={validators.required}
									variant='outlined'
								/>

								{/* Below field is not used as of now */}
								<Field
									name='modelsToGenerate'
									render={({ input }) => (
										<Stack
											alignItems='center'
											direction='row'
											display='none'
											justifyContent='center'
											spacing={1}
										>
											<IconButton
												aria-label='Decrease number of generated models'
												data-cypress='SliderNumberDecrease'
												onClick={() => {
													if (input.value > 1) {
														input.onChange(input.value - 1);
													}
												}}
												style={{ margin: '15px' }}
											>
												<RemoveCircleOutlineIcon />
											</IconButton>

											<Slider
												aria-label='Models to generate'
												max={4}
												min={1}
												onChange={(_e, value) => {
													input.onChange(value); // Update the value in the form
												}}
												step={1}
												sx={{
													width: '90%',
													'.MuiSlider-valueLabel': {
														borderRadius: '8px',
														backgroundColor: 'step.completed',
													},
												}}
												value={input.value} // Set the value from the form
												valueLabelDisplay='on'
											/>

											<IconButton
												aria-label='Increase number of generated models'
												data-cypress='SliderNumberIncrease'
												onClick={() => {
													if (input.value < 4) {
														input.onChange(input.value + 1);
													}
												}}
												style={{ margin: '15px' }}
											>
												<AddCircleOutlineIcon />
											</IconButton>
										</Stack>
									)}
									type='range'
								/>

								<>
									<Divider sx={{ pt: 2 }} />

									<Stack
										direction='column'
										spacing={1}
										sx={{
											margin: 'auto 5% !important',
											pt: 2,
											pb: 2,
										}}
									>
										<Stack
											alignItems='center'
											direction='row'
											justifyContent='space-between'
											sx={{
												pt: 2,
											}}
										>
											<Typography>{GenModelConsts.creditCost}</Typography>
											<Stack alignItems='center' direction='row' spacing={1}>
												<CreditsIcon />
												<Box>
													{GenModelConsts.oneModelCredit} {GenModelConsts.credits}
												</Box>
											</Stack>
										</Stack>

										<Stack alignItems='center' direction='row' justifyContent='space-between'>
											<Typography>{GenModelConsts.estimatedTime}</Typography>

											<Typography>{GenModelConsts.estimatedTimeValue}</Typography>
										</Stack>
									</Stack>
								</>
								{!currentUser ? (
									<LoadingButton
										onClick={async () => {
											if (form.getFieldState('prompt')?.value) {
												localStorage.setItem(
													'promptBeforeLogIn',
													form.getFieldState('prompt')?.value,
												);
											}

											if (inApp) {
												router.push(urls.routes.deviceLogin);
											} else {
												await signIn('auth0');
											}
										}}
										sx={{
											background: (theme) => theme.palette.gradient.main,
											borderRadius: '100px',
											fontSize: 'clamp(0.5rem, 1rem, 1rem)',
											fontWeight: '700',
											letterSpacing: '0.0025em',
											lineHeight: '32px',
											width: '100%',
										}}
										variant='contained'
									>
										{GenModelConsts.loginToGenerate}
									</LoadingButton>
								) : (
									<LoadingButton
										disabled={submitting}
										loading={submitting}
										sx={{
											background: (theme) => theme.palette.gradient.main,
											borderRadius: '100px',
											fontSize: 'clamp(0.5rem, 1rem, 1rem)',
											fontWeight: '700',
											letterSpacing: '0.0025em',
											lineHeight: '32px',
										}}
										type='submit'
										variant='contained'
									>
										{currentUser && (
											// eslint-disable-next-line @next/next/no-img-element
											<RemixAIIcon
												sx={{
													width: '20px',
													height: '20px',
													margin: '1% 2.5%',
												}}
											/>
										)}
										{submitting ? GenModelConsts.generating : GenModelConsts.generate3DModel}
									</LoadingButton>
								)}
							</Stack>
						)}
						{activeStep === StepNames.RESULTS && (
							<Box p={4}>
								<ResultsStep />
							</Box>
						)}
					</>
				)}
			</Form>
		</div>
	);
}

export default OnePromptTextTo3D;
