/* eslint-disable consistent-return, no-nested-ternary */
import { matchBucketToPostgresDataFormat } from '@mpx-sdk/helpers/assets';
import { getMyBalance } from '@mpx-sdk/helpers/economy';
import { defaultThumbnail } from '@mpx-sdk/images';
import { inAppBrowserAtom, queueStatus, userSelectedMeshIdsAtom } from '@mpx-sdk/shared/atoms';
import { urls } from '@mpx-sdk/shared/configs';
import DeleteAsset from '@mpx-sdk/ui/components/delete-asset/DeleteAsset';
import { DownloadDone, Error } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	CircularProgress,
	Grid,
	LinearProgress,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import { usePreviousProps } from '@mui/utils';
import { useAtom, useAtomValue } from 'jotai';
import { capitalize, isEmpty } from 'lodash';
import moment from 'moment';
import Link from 'next/link';
import { type ReactElement, useEffect } from 'react';

interface HistoryCardProps {
	data?: any;
	downloaded?: any;
	// glbUrl?: string;
	onClick?: () => void;
	thumbnailUrl?: string;
	title?: string;
	/**	Whether to display the card in a grid view or rows */
	gridView?: boolean;
	/** Whether to display the card in a drawer [true, default] or not */
	drawer?: boolean;
}

/** Display project hards for the users generation history */
export default function HistoryCard({
	data,
	downloaded,
	// glbUrl = '',
	onClick,
	thumbnailUrl = defaultThumbnail?.src || defaultThumbnail,
	title = 'Untitled Project',
	gridView = false,
	drawer = true,
}: Readonly<HistoryCardProps>): ReactElement {
	const inApp = useAtomValue(inAppBrowserAtom);
	const previousData = usePreviousProps(data);
	const qStatus = useAtomValue(queueStatus);
	const [meshIds, setMeshIds] = useAtom(userSelectedMeshIdsAtom);
	const { id } = data;

	const qIndex = qStatus?.requests.find((request: { id: string }) => request.id === id);

	const displayLoading =
		(data?.progress < 100 && data?.progress > 0 && data?.status !== 'failed') ||
		qIndex?.qIndex >= 0 ||
		qIndex?.inProgress ||
		(data?.status && ['dispatching', 'processing', 'pending'].includes(data.status));

	const getMeshType = () => {
		if (data.data.meshType === 'human') {
			return 'Human';
		}
		if (data.data.meshType === 'object') {
			return 'Object';
		}
		if (data.data.meshType === 'animal') {
			return 'Animal';
		}
		return '';
	};

	useEffect(() => {
		if (data.status === 'failed' && previousData?.status !== data?.status && !isEmpty(previousData)) {
			getMyBalance();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const formatDate = (createdAt: { toDate: () => moment.MomentInput }) => {
		if (!createdAt || !createdAt?.toDate) {
			return 'Unknown creation date';
		}

		try {
			const now = moment();
			const createdDate = moment(createdAt.toDate());
			if (now.diff(createdDate, 'days') > 7) {
				// createdDate.format('YYYY-MM-DD');
				// return createdDate.format('YY/MM');
				return createdDate.format('MMMM DD, YYYY');
			}

			return createdDate.fromNow();
		} catch (error) {
			console.error(`Error formatting date of ${createdAt}`, error);
			return 'Unknown creation date';
		}
	};

	const handleSelectMesh = () => {
		if (meshIds.includes(id)) {
			setMeshIds((prevMeshIds) => prevMeshIds.filter((meshId) => meshId !== data.id));
		} else {
			setMeshIds((prevMeshIds) => [...prevMeshIds, data.id]);
		}
	};

	return (
		<Grid
			item
			sx={{
				width: '100%',
				height: '100%',
			}}
			xs={gridView && drawer ? 6 : 12}
		>
			<Tooltip
				arrow
				describeChild
				title={displayLoading ? `${capitalize(data.status)}... ${data?.progress?.toFixed(2) ?? 0}%` : ''}
			>
				<Card
					sx={{
						backgroundColor: 'background.default',
						border: (theme) => `1px solid ${theme.palette.background.border}`,
						borderRadius: '12px',
						height: '100%',
						minHeight: '100% !important',
						minWidth: '100% !important',
						transition: 'border 0.2s ease-in-out',
						position: 'relative',
						width: '100%',
						'.MuiCardMedia-root': {
							borderRadius: drawer ? '12px' : '0px',
						},
						'&:hover': {
							border: (theme) => `1px solid ${theme.palette.primary.main}`,
							'& .radio-button': {
								visibility: 'visible',
							},
						},
					}}
				>
					<Radio
						checked={meshIds.includes(id)}
						checkedIcon={<CheckCircleIcon />}
						className='radio-button'
						onClick={handleSelectMesh}
						sx={{
							position: 'absolute',
							left: 0,
							zIndex: 100,
							visibility: meshIds.includes(id) ? 'visible' : 'hidden',
						}}
					/>

					<CardActionArea
						component='div'
						onClick={() => {
							if (data?.status !== 'failed') {
								onClick?.();
							}
						}}
						sx={{
							cursor: data.status === 'failed' ? 'initial' : 'pointer',
							display: !gridView ? 'flex' : '',
							justifyContent: !gridView ? 'flex-start' : 'initial',
							width: '100%',
							'&.Mui-disabled': { opacity: 0.5 },
							'.MuiCardActionArea-focusHighlight': {
								backgroundColor: 'transparent',
							},
						}}
					>
						<CardMedia
							alt='Card Image'
							component='img'
							image={thumbnailUrl || defaultThumbnail?.src || defaultThumbnail}
							loading='lazy'
							onError={(e: { currentTarget: { src: string } }) => {
								e.currentTarget.src = defaultThumbnail?.src || defaultThumbnail;
							}}
							sx={{
								alignItems: 'center',
								bgcolor: '#4a4c55',
								display: 'flex',
								height: 'auto',
								justifyContent: 'center',
								minHeight: '100px',
								minWidth: !gridView ? '35%' : '100%',
								objectFit: 'cover',
								position: 'relative',
								width: !gridView ? '35%' : '100%',
								span: {
									width: '100%',
									height: '100%',
								},
								svg: {
									width: '100%',
									height: '100%',
								},
							}}
						/>

						{displayLoading ? (
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									height: '100%',
									justifyContent: gridView ? 'center' : 'flex-start',
									position: 'absolute',
									top: 0,
									width: '100%',
								}}
							>
								<CircularProgress
									sx={{
										marginLeft: gridView ? 0 : '3.5rem',
										marginTop: gridView ? '-5.5rem' : 0,
									}}
								/>
							</Box>
						) : null}

						<CardContent sx={{ maxWidth: '70%', padding: '2.5% 4.5%', textAlign: 'left' }}>
							<Typography
								aria-label={title}
								component='div'
								mb={0.5}
								noWrap
								sx={{ textOverflow: 'ellipsis' }}
								variant='body1'
							>
								{title}
							</Typography>

							<Typography color='text.disabled' variant='body2'>
								{getMeshType()}
							</Typography>

							{qIndex && qIndex.qIndex >= 0 && !qIndex.inProgress ? (
								<Typography color='text.secondary' variant='body2'>
									{qIndex.qIndex}/{qStatus?.queueLength} in queue
								</Typography>
							) : data?.status && displayLoading ? (
								<Typography color='text.secondary' variant='body2'>
									{capitalize(data.status)}...
								</Typography>
							) : data?.status === 'failed' ? (
								<Typography color='error' variant='body2'>
									Failed
								</Typography>
							) : null}

							<Typography color='text.disabled' variant='body2'>
								{formatDate(data.createdAt)}
							</Typography>

							<Stack direction='column'>
								{data?.status === 'failed' && (
									<Tooltip
										arrow
										describeChild
										placement='top'
										title={
											<Stack direction='column' spacing={1}>
												<Typography>
													Unfortunately, this generation failed. But don&apos;t worry! Your
													credits will be returned to your account.
												</Typography>

												<Typography
													sx={{
														a: {
															color: (theme) => `${theme.palette.text.action} !important`,
														},
													}}
												>
													Please try again later or contact us for{' '}
													<Link
														href={urls.routes.feedback}
														{...(!inApp &&
														!window.matchMedia('(display-mode: standalone)').matches
															? {
																	target: '_blank',
																	rel: 'noopener noreferrer',
																}
															: {})}
													>
														help
													</Link>
													.
												</Typography>

												<Typography>Asset ID: {id}</Typography>
											</Stack>
										}
									>
										<Typography
											color='error'
											sx={{
												bottom: 40,
												cursor: 'help',
												position: 'absolute',
												right: 18,
											}}
											variant='caption'
										>
											<Error />
										</Typography>
									</Tooltip>
								)}
								{downloaded && (
									<Tooltip title='Downloaded'>
										<Typography
											color='primary'
											sx={{
												position: 'absolute',
												bottom: 6,
												right: 50,
											}}
											variant='caption'
										>
											<DownloadDone />
										</Typography>
									</Tooltip>
								)}
							</Stack>
						</CardContent>
					</CardActionArea>
					{!displayLoading && (
						<DeleteAsset
							projectData={matchBucketToPostgresDataFormat(data)}
							stylingType='icon'
							sx={{
								bottom: 5,
								position: 'absolute',
								right: 10,
							}}
						/>
					)}
					{displayLoading && (
						<LinearProgress
							value={
								data?.progress !== 100 &&
								data?.progress >= 0 &&
								!moment(data.createdAt.toDate()).isBefore(moment().subtract(1, 'h'))
									? data.progress
									: 0
							}
							variant={
								data?.progress !== 100 &&
								data?.progress >= 0 &&
								!moment(data.createdAt.toDate()).isBefore(moment().subtract(1, 'h'))
									? 'determinate'
									: 'indeterminate'
							}
						/>
					)}
				</Card>
			</Tooltip>
		</Grid>
	);
}
