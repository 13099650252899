import IconTooltip from '@mpx-sdk/ui/components/tooltips/IconTooltip';
import {
	AddCircleOutline as AddCircleOutlineIcon,
	RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@mui/icons-material';
import {
	Box,
	Card,
	CardActionArea,
	CardMedia,
	Grid,
	IconButton,
	RadioGroup,
	Slider,
	Stack,
	Typography,
} from '@mui/material';
import { type ReactElement } from 'react';
import { Field, useForm } from 'react-final-form';

/** The start step for the text to 3D generation process */
export default function StartStep(): ReactElement {
	const form = useForm();

	const modelTypeOptions = [
		{ label: 'Object', value: 'object', image: '/object.png' },
		{ label: 'Animal', value: 'animal', image: '/animal.png' },
		{ label: 'Humanoid ', value: 'human', image: '/humanoid.png' },
	];

	const handleModelTypeChange = (value: string) => {
		form.change('meshType', value);
	};

	return (
		<Stack direction='column' spacing={2}>
			<Typography
				fontWeight='bold'
				sx={{
					paddingLeft: 'min(6px, 1%)',
				}}
			>
				Select your 3D model category
				<IconTooltip
					alertIconText={[
						<Typography key='model-type-tooltip-limitations' variant='body2'>
							<strong>NOTE: Animations are only available for Human models.</strong>
						</Typography>,
					]}
					paragraphs={[
						<Typography key='model-type-tooltip-capabilities-list' variant='caption'>
							<p>
								<strong>Objects:</strong> Craft anything from simple gadgets to household items. Perfect
								for prototypes or fantasy artifacts.
							</p>
							<p>
								<strong>Animals:</strong> Bring to life a diverse kingdom, from the familiar furry
								friends to small critters.
							</p>
							<p>
								<strong>Humans:</strong> Create digital beings, from soldiers to pop stars.
							</p>
						</Typography>,
					]}
					showAlertIcon // Show the Alert button
				/>
			</Typography>

			<Field
				name='meshType'
				render={({ input }) => (
					<Grid
						component={RadioGroup}
						{...input}
						alignItems='stretch'
						container
						sx={{
							'& .MuiGrid-item': {
								paddingLeft: 'min(6px, 1%)',
								paddingRight: 'min(6px, 1%)',
							},
						}}
					>
						{modelTypeOptions.map(({ label, value, image }) => (
							<Grid key={label} item xs={4}>
								<Box
									sx={{
										height: '100%',
										display: 'flex',
									}}
									textAlign='center'
								>
									<Stack>
										<Card
											component={Stack}
											sx={{
												transition: 'all 0.1s ease-in-out',
												flexGrow: 1,
												background: (theme) =>
													input.value === value ? theme.palette.gradient.main : '',
												padding: 0.25,
											}}
										>
											<CardActionArea
												data-cypress='RadioGroup'
												onClick={() => handleModelTypeChange(value)}
												sx={{
													backgroundColor: 'background.cards',
													pb: 1,
													height: '100%',
												}}
											>
												<CardMedia
													alt='Card Image'
													component='img'
													image={image}
													loading='lazy'
													sx={{
														padding: 1.5,
														height: 'auto',
														maxWidth: '100%',
														objectFit: 'cover',
													}}
												/>

												<Typography textAlign='center' variant='body2'>
													{label}
												</Typography>
											</CardActionArea>
										</Card>
									</Stack>
								</Box>
							</Grid>
						))}
					</Grid>
				)}
			/>

			{/* Below fields is not used as of now */}
			<Box display='none'>
				<Typography
					fontWeight='bold'
					sx={{
						paddingLeft: 'min(6px, 1%)',
					}}
				>
					How many models do you want?
					<IconTooltip
						paragraphs={[
							'Choose the number of 3D models you want to generate. The more generations, the more variety in the results.',
							'You can continue generating more while waiting for your models to process.',
						]}
					/>
				</Typography>
			</Box>

			<Field
				name='modelsToGenerate'
				render={({ input }) => (
					<Stack alignItems='center' direction='row' display='none' justifyContent='center' spacing={1}>
						<IconButton
							aria-label='Decrease number of generated models'
							data-cypress='SliderNumberDecrease'
							onClick={() => {
								if (input.value > 1) {
									input.onChange(input.value - 1);
								}
							}}
							style={{ margin: '15px' }}
						>
							<RemoveCircleOutlineIcon />
						</IconButton>

						<Slider
							aria-label='Models to generate'
							max={4}
							min={1}
							onChange={(_e, value) => {
								input.onChange(value); // Update the value in the form
							}}
							step={1}
							sx={{
								width: '90%',
								'.MuiSlider-valueLabel': {
									borderRadius: '8px',
									backgroundColor: 'step.completed',
								},
							}}
							value={input.value} // Set the value from the form
							valueLabelDisplay='on'
						/>

						<IconButton
							aria-label='Increase number of generated models'
							data-cypress='SliderNumberIncrease'
							onClick={() => {
								if (input.value < 4) {
									input.onChange(input.value + 1);
								}
							}}
							style={{ margin: '15px' }}
						>
							<AddCircleOutlineIcon />
						</IconButton>
					</Stack>
				)}
				type='range'
			/>
		</Stack>
	);
}
