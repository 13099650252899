import { genaiFirebase } from '@mpx-sdk/helpers/assets/genAI';
import { nanoid } from '@mpx-sdk/shared/utils';
import { getAuth } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';

class MLSession {
	id: string;

	storageRoot: string;

	createdAt: Timestamp;

	sessionId: string | MLSession;

	generatedModelsCount: number;

	constructor(id?: string) {
		const user = getAuth(genaiFirebase).currentUser;

		if (!user) {
			toast.error('Unable to create asset generation. Please login or refresh the page if you are logged in.');
			throw new Error('MLSession.constructor: User not logged in');
		}

		this.id = id ?? nanoid(4);
		this.storageRoot = `ml-sessions/${user.uid}/${this.id.trim()}`;
		this.createdAt = Timestamp.now();
		this.generatedModelsCount = 0;

		// Save in local storage
		localStorage.setItem('ml-session', JSON.stringify(this));
	}
}

export default MLSession;
