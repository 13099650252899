import { Done as DoneIcon, ModeEditOutlineOutlined as ModeEditOutlineOutlinedIcon } from '@mui/icons-material';
import { Box, ClickAwayListener, IconButton, InputBase, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Field, useForm } from 'react-final-form';

function generateModelName(meshType: any) {
	const date = moment().format('MMDDYYHHmm');
	return `${meshType}_${date}`;
}

// eslint-disable-next-line react/prop-types
function ProjectTitle({ autoSetName = true }) {
	const [isEditMode, setIsEditMode] = useState(false);
	const form = useForm();
	const inputRef = useRef(null);

	const meshTypeValue = form.getFieldState('meshType');
	// Test
	useEffect(() => {
		if (autoSetName) {
			form.change('projectTitle', generateModelName(meshTypeValue?.value || ''));
		}
	}, [autoSetName, form, meshTypeValue]);

	const handleEditMode = () => {
		setIsEditMode(true);
	};

	const handleSave = (e: any) => {
		e.stopPropagation();
		setIsEditMode(false);
	};

	const handleTitleClick = () => {
		if (!autoSetName) {
			setIsEditMode(true);
		}
	};

	return (
		<Stack
			alignItems='center'
			component={Typography}
			direction='row'
			justifyContent='space-between'
			p={1}
			variant='h5'
		>
			<Field
				name='projectTitle'
				render={({ input }) => (
					<>
						<Box
							onClick={handleTitleClick}
							sx={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
							}}
						>
							<Box sx={{ flexGrow: 1 }}>
								{isEditMode ? (
									<ClickAwayListener onClickAway={handleSave}>
										<InputBase
											ref={inputRef}
											autoFocus
											fullWidth
											inputProps={{
												'aria-label': 'project title',
												...input,
											}}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													handleSave(e);
												}
											}}
											sx={{ fontSize: 'inherit' }}
										/>
									</ClickAwayListener>
								) : (
									<div ref={inputRef}>{input.value}</div>
								)}
							</Box>
						</Box>
						<Tooltip arrow describeChild title={!isEditMode ? 'Edit Project Title' : 'Save'}>
							<IconButton
								aria-label={!isEditMode ? 'Edit Project Title' : 'Save'}
								onClick={!isEditMode ? handleEditMode : handleSave}
							>
								{!isEditMode ? <ModeEditOutlineOutlinedIcon /> : <DoneIcon />}
							</IconButton>
						</Tooltip>
					</>
				)}
			/>
		</Stack>
	);
}

export default ProjectTitle;
