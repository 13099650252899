import { UIHelper } from '@mpx-sdk/helpers/ui';
import { MeshType } from '@mpx-sdk/types';
import FormField from '@mpx-sdk/ui/components/core/form/FormField';
import IconTooltip from '@mpx-sdk/ui/components/tooltips/IconTooltip';
import TooltipChips from '@mpx/components/generate/text-to-3d/TooltipChips';
import { Box, Card, CardActionArea, CardMedia, Collapse, Grid, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { type ReactElement } from 'react';
import { Field, useForm } from 'react-final-form';

const autofillOptions: Record<string, string[]> = {
	human: [
		'Walking',
		'Jumping',
		'Punching',
		'Kicking',
		'Waving',
		'Crouching',
		'Climbing',
		'Standing',
		'Dancing',
		'Clapping',
	],
};

const limitations = {
	[MeshType.HUMAN]: [
		'Currently only English language words are supported',
		'Remember to have fun exploring & being inspired by your creative helper/intern!',
	],
};

const modelTypeOptions = [
	{ label: 'No Bones or Animation ', value: 'none', image: '/No_Rig.png' },
	{ label: 'Bones Only', value: 'rig_only', image: '/Rig_Only.png' },
	{ label: 'Bones & Animation', value: 'rig_animation', image: '/Rig_And_Bones.png' },
];

/** The animation step for the text to 3D generation process */
export default function AnimateStep(): ReactElement {
	const form = useForm();
	const responsiveView = UIHelper.isResponsiveView();

	const handleAutofillClick = (text: string) => {
		form.change('movesLikePrompt', `${text}`);
	};

	const getMeshType = () => {
		const meshType = form.getFieldState('meshType')?.value;
		if (meshType === 'human') {
			return 'human';
		}
		if (meshType === 'animal') {
			return 'animal';
		}
		return 'object';
	};

	return (
		<Stack direction='column' spacing={1}>
			<Typography variant='h6'>Animation Details</Typography>

			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Typography>Do you want your model to have:</Typography>

				{/* Add the IconTooltip component here */}
				<IconTooltip
					paragraphs={[
						<Stack key='animation-style-tooltip' direction='column' spacing={2}>
							<Typography
								key='animation-style-tooltip-0'
								sx={{
									fontWeight: 'bold',
								}}
							>
								Select your preference for your human model generation:
							</Typography>
							<Typography key='animation-style-tooltip-1'>
								<b>Bones Only</b> enables you to animate your human model within other applications.
							</Typography>
							<Typography key='animation-style-tooltip-2'>
								<b>None</b> will generate your human model without bones or animation.
							</Typography>
							<Typography key='animation-style-tooltip-3'>
								<b>Bones & Animation</b> will create your model with bones and integrated animation.
							</Typography>
						</Stack>,
					]}
				/>
			</Box>

			<Field
				name='animationType'
				render={({ input }) => (
					<Grid
						alignItems='stretch'
						container
						sx={{
							width: '100%',
							'& .MuiGrid-item': {
								paddingLeft: 'min(6px, 1%)',
								paddingRight: 'min(6px, 1%)',
							},
						}}
					>
						{modelTypeOptions.map(({ label, value, image }) => (
							<Grid
								key={label}
								item
								onClick={() => input.onChange(value)}
								sx={{
									cursor: 'pointer',
								}}
								xs={4}
							>
								<Box height='100%' textAlign='center'>
									<Card
										component={Stack}
										sx={{
											boxShadow: (theme) =>
												input.value === value
													? `0 0 0 2px ${theme.palette.primary.main}`
													: 'rig_only', // default
										}}
									>
										<CardActionArea>
											<CardMedia
												alt='Card Image'
												component='img'
												image={image}
												loading='lazy'
												sx={{
													height: '8rem',
													padding: 1,
													maxWidth: '100%',
													objectFit: 'contain',
													margin: 'auto',
												}}
											/>
											<Typography fontSize='0.9rem' height='3rem' textAlign='center'>
												{label}
											</Typography>
										</CardActionArea>
									</Card>
								</Box>
							</Grid>
						))}
					</Grid>
				)}
			/>

			<Stack spacing={2}>
				<Typography color='error' hidden={form.getFieldState('meshType')?.value === MeshType.HUMAN}>
					<b>Animation is currently only available for human models.</b>
				</Typography>

				<Collapse in={form.getFieldState('animationType')?.value === 'rig_animation'}>
					<Stack pt={1} spacing={1}>
						<FormField
							disabled={form.getFieldState('meshType')?.value !== MeshType.HUMAN}
							fullWidth
							interactive
							multiline
							name='movesLikePrompt'
							placeholder='Simple actions are best. Like walking, jumping, or standing'
							rows={2}
							title={`How does your ${form.getFieldState('meshType')?.value} move?`}
							tooltip={
								responsiveView
									? {
											title: 'Try These Examples',
											content: [
												<TooltipChips
													key='chipslist4'
													chips={autofillOptions[getMeshType()]}
													onClick={(option) => handleAutofillClick(option)}
												/>,
												<>
													<div
														key='limitations'
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<Image
															alt='Limitations-Alert'
															height={30}
															src='/alert-triangle.svg'
															width={15}
														/>
														&nbsp;
														<Typography fontWeight='bold' variant='body2'>
															Limitations for humanoid moves:
														</Typography>
													</div>

													<Typography component='ul' variant='caption'>
														{limitations[MeshType.HUMAN].map((limitation) => (
															<li key={limitation}>{limitation}</li>
														))}
													</Typography>
												</>,
											],
										}
									: undefined
							}
							variant='outlined'
						/>

						{!responsiveView && (
							<TooltipChips
								key='chipslist4'
								chips={autofillOptions[getMeshType()]}
								onClick={(option) => handleAutofillClick(option)}
							/>
						)}
					</Stack>
				</Collapse>
			</Stack>
		</Stack>
	);
}
