import { Stack, type StackProps, Typography, type TypographyProps } from '@mui/material';
import { type ReactNode } from 'react';

function ImageInfo({
	imageUrl,
	children,
	TypographyProps,
	...props
}: {
	imageUrl: string;
	children?: ReactNode;
	TypographyProps?: TypographyProps;
} & StackProps) {
	return (
		<Stack alignItems='center' justifyContent='center' mt={3} mx='auto' {...props}>
			<Stack alignItems='center' direction='column' spacing={2}>
				{/* eslint-disable-next-line @next/next/no-img-element */}
				<img alt='Remix History' src={imageUrl} style={{ width: '100%' }} />

				<Typography align='center' color='secondary.light' lineHeight={2} variant='body2' {...TypographyProps}>
					{children}
				</Typography>
			</Stack>
		</Stack>
	);
}

export default ImageInfo;
