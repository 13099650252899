import { queueStatus } from '@mpx-sdk/shared/atoms';
import axios from 'axios';
import { useAtom } from 'jotai';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const queueHighWorkloadThreshold = 100;
const INTERVAL_TIME = 10000; // 10 seconds

export interface Request {
	/** The ID of the request. */
	id: string;
	/** The queue index of the request. */
	qIndex: number;
	/** Indicates if the request is in progress. */
	inProgress: boolean;
}

export interface ApiResponse {
	/** The length of the queue. */
	queueLength: number;
	/** The number of workers in progress.  */
	workersInProgress: number;
	/** The list of requests. */
	requests: Request[];
}

const API_URL = process.env.NEXT_PUBLIC_QUEUE_API;

const getQStatus = async (
	/** An array of request IDs */
	requestIds: string[],
): Promise<ApiResponse | null> => {
	if (!API_URL) {
		throw new Error('Queue API URL not set');
	}

	try {
		const requestBody = { requests: requestIds };

		const response = await axios.post(API_URL, requestBody);

		return response?.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error.code === 'ECONNABORTED' || error.code === 'ENOTFOUND' || error.code === 'ERR_NETWORK') {
				return Promise.reject(error);
			}

			if (!error?.response) return Promise.reject(error);
		}

		// Handle any error that occurred during the API call
		console.error('Failed to fetch queue status in getQStatus', JSON.stringify(error, null, 2));
		throw new Error('Failed to fetch queue status');
	}
};

/** Hook to update the queue status every 10 seconds */
const useQueueStatus = (history: any) => {
	const [qStatus, setQStatus] = useAtom(queueStatus);
	const [startFetching, setStartFetching] = useState(false);
	const [queueRes, setQueueRes] = useState<ApiResponse | null>(null);
	const [queueError, setQueueError] = useState<string | null>(null);

	function checkHighWorkload() {
		if (queueRes?.queueLength && queueRes.queueLength > queueHighWorkloadThreshold) {
			toast.warning("We're facing high volume right now. It may take longer than usual to process your request.");
		}
	}

	const fetchQStatus = async () => {
		try {
			// Grab all session IDs from the history
			const requestIds = history.map((item) => item.id);

			const apiResponse = await getQStatus(requestIds);
			// If the queue status has changed, update the queue status atom
			if (!isEqual(apiResponse, qStatus) && apiResponse) {
				setQStatus(apiResponse);
				checkHighWorkload();
			}
			setQueueRes(apiResponse);
			setQueueError(null);
		} catch (error) {
			console.info('Error retrieving queue status', error);
			setQueueError('Unable to retrieve queue status');
		}
	};

	const startFetchingQStatus = () => {
		setStartFetching(true);
	};

	useEffect(() => {
		if (startFetching) {
			const interval = setInterval(fetchQStatus, INTERVAL_TIME);

			return () => clearInterval(interval);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startFetching]);

	return { queueRes, queueError, startFetchingQStatus };
};

export default useQueueStatus;
