import { UIHelper } from '@mpx-sdk/helpers/ui';
import { MeshType } from '@mpx-sdk/types';
import FormField, { defaultMaxLength } from '@mpx-sdk/ui/components/core/form/FormField';
import IconTooltip from '@mpx-sdk/ui/components/tooltips/IconTooltip';
import TooltipChips from '@mpx/components/generate/text-to-3d/TooltipChips';
import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import { Box, Button, Collapse, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { useState } from 'react';
import { useForm } from 'react-final-form';

const autofillStyles = ['Sci-Fi', 'Cyberpunk', 'Psychedelic', 'Masterpiece', 'Comic', 'Retro', 'Art Deco'];

const autofillDetails = ['Beautiful', 'Realistic', 'Cartoon', 'Detailed', 'Pixelated'];

const autofillLighting = [
	'Soft Lighting',
	'Volume Lighting',
	'Cinematic Lighting',
	'Perfect Lighting',
	'Vivid Lighting',
];

const autofillNegative = [
	'Ugly',
	'Blurry',
	'Messy',
	'Dull',
	'Low-resolution',
	'Low-quality',
	'Bad Anatomy',
	'Unnatural Colors',
	'Deformed',
];

const limitations = {
	[MeshType.HUMAN]: [
		'Currently only English language words are supported',
		'These are prototype textures generated over the models - they may exhibit some quality variation',
		'No accessories',
		'Limited hair styles (no spikes, mullets)',
		'Limited footwear (no specifics outside of “shoes/boots”)',
		'No layers or specific garments of clothing (robes, bomber jackets)',
		'Remember to have fun exploring & being inspired by your creative helper/intern!',
	],
};

export default function PaintStep() {
	const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
	const form = useForm();
	const responsiveView = UIHelper.isResponsiveView();

	const autofillButton = (text: string, fieldState: string) => {
		let currentValue = form.getFieldState(fieldState)?.value || '';

		if (currentValue !== '') {
			currentValue += ', ';
		}

		const newValue = `${currentValue}${text}`;
		if (newValue?.length <= defaultMaxLength) {
			form.change(fieldState, newValue);
		}
	};

	return (
		<>
			<Typography variant='h6'>Texture Details</Typography>

			<Stack spacing={4}>
				<Stack pt={1} spacing={1}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography>How do you imagine your model to look?</Typography>

						<IconTooltip
							key='tooltip-shape-step-word-count'
							paragraphs={[
								<>
									<b>Ask yourself: </b>
									<ul>
										<li>How do you want your model to look like?</li>
										<li>What are the colors? </li>
										<li>What is the style of the model?</li>
										<li>If generating a human, is it female/woman or male/man?</li>
									</ul>
								</>,
							]}
						/>
					</Box>

					<FormField
						fullWidth
						interactive
						multiline
						name='promptPos'
						placeholder='Describe the style you imagine your model to have. Try to keep it simple.'
						rows={4}
						title=''
						tooltip={
							responsiveView
								? {
										title: 'Try These Examples',
										content: [
											<Typography key='relatedToStyle'>Related to Style:</Typography>,
											<TooltipChips
												key='chipList2'
												chips={autofillStyles}
												onClick={(option) => autofillButton(option, 'promptPos')}
											/>,

											<Typography key='relatedToDetail'>Related to Detail:</Typography>,
											<TooltipChips
												key='chipList3'
												chips={autofillDetails}
												onClick={(option) => autofillButton(option, 'promptPos')}
											/>,

											<Typography key='relatedToLighting'>Related to Lighting:</Typography>,
											<TooltipChips
												key='chipList3'
												chips={autofillLighting}
												onClick={(option) => autofillButton(option, 'promptPos')}
											/>,
											<>
												<div
													key='limitations'
													style={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Image
														alt='Limitations-Alert'
														height={30}
														src='/alert-triangle.svg'
														width={15}
													/>
													&nbsp;
													<Typography fontWeight='bold' variant='body2'>
														Limitations for humanoid models:
													</Typography>
												</div>

												<Typography component='ul' variant='caption'>
													{limitations[MeshType.HUMAN].map((limitation) => (
														<li key={limitation}>{limitation}</li>
													))}
												</Typography>
											</>,
										],
									}
								: undefined
						}
						variant='outlined'
					/>

					{!responsiveView && (
						<TooltipChips
							key='chipListResponsive1'
							chips={[...autofillStyles, ...autofillDetails, ...autofillLighting]}
							onClick={(option) => autofillButton(option, 'promptPos')}
						/>
					)}
				</Stack>

				<Button
					aria-label='Open advanced settings with more options'
					onClick={() => {
						setIsAdvancedOpen((isOpen) => !isOpen);
					}}
				>
					Advanced Settings {!isAdvancedOpen ? <ChevronRight /> : <KeyboardArrowDown />}
				</Button>

				<Collapse in={isAdvancedOpen}>
					<Stack spacing={1}>
						<FormField
							fullWidth
							interactive
							multiline
							name='promptNeg'
							placeholder='This field is optional. But, filling it out will yield better results.'
							rows={4}
							title='What does your model NOT look like?'
							tooltip={
								responsiveView
									? {
											title: 'Negative Prompt',
											content: [
												`What you DON'T want your model to look like`,

												<TooltipChips
													key='chipList4'
													chips={autofillNegative}
													onClick={(option) => autofillButton(option, 'promptNeg')}
												/>,
											],
										}
									: undefined
							}
							variant='outlined'
						/>

						{!responsiveView && (
							<TooltipChips
								key='chipListResponsive3'
								chips={autofillNegative}
								onClick={(option) => autofillButton(option, 'promptNeg')}
							/>
						)}
					</Stack>
				</Collapse>
			</Stack>
		</>
	);
}
