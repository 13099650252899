'use client';

/* eslint-disable no-nested-ternary */
import { matchBucketToPostgresDataFormat } from '@mpx-sdk/helpers/assets';
import customHistoryFilter, { genaiFirebase, getHistoryData } from '@mpx-sdk/helpers/assets/genAI';
import { DeleteIcon } from '@mpx-sdk/images';
import { singleAssetViewAtom, userAtom, userHistoryAtom, userSelectedMeshIdsAtom } from '@mpx-sdk/shared/atoms';
import { OverlayLoading } from '@mpx-sdk/ui/components';
import ConfirmDelete from '@mpx-sdk/ui/components/delete-asset/ConfirmDelete';
import ImageInfo from '@mpx/app/generate/ImageInfo';
import Filter from '@mpx/components/generate/history/Filter';
import HistoryCard from '@mpx/components/generate/history/HistoryCard';
import QueueDisplay from '@mpx/components/generate/history/QueueDisplay';
import Sorting from '@mpx/components/generate/history/Sorting';
import {
	CheckCircle as CheckCircleIcon,
	GridView as GridViewIcon,
	Splitscreen as SplitscreenIcon,
} from '@mui/icons-material';
import { Box, Button, Divider, Grid, IconButton, Radio, Stack, Tooltip, Typography } from '@mui/material';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { type ReactElement, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export default function HistoryDrawer(): ReactElement {
	const [currentFilter, setCurrentFilter] = useState(['all']);
	const [currentSorting, setCurrentSorting] = useState('Newest');
	const [gridView, setGridView] = useState(false);
	const [history, setHistory] = useAtom(userHistoryAtom);
	const [loadingHistory, setLoadingHistory] = useState(false);
	const currentUser = useAtomValue(userAtom);
	const router = useRouter();
	const setProjectData = useSetAtom(singleAssetViewAtom);
	const { auth0Id: userId } = (currentUser as any) || {};
	const [meshIds, setMeshIds] = useAtom(userSelectedMeshIdsAtom);
	const [confirmDeletePopUp, setConfirmDeletePopUp] = useState(false);
	const toastID = useRef<any>(null);

	async function historyData() {
		if (userId) {
			setLoadingHistory(true);

			await getHistoryData(userId, setHistory, currentSorting === 'Oldest' ? 'asc' : 'desc');

			setLoadingHistory(false);
		}
	}

	useEffect(() => {
		historyData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	const toggleSelectAll = () => {
		if (meshIds.length === history.length) {
			setMeshIds([]);
		} else {
			setMeshIds(history.map((item) => item.id));
		}
	};

	const deleteProject = async (projectId: string) => {
		const projectDoc = doc(getFirestore(genaiFirebase), 'ml-requests', projectId);
		await deleteDoc(projectDoc);
	};

	const handleDeleteProjects = () => {
		if (!confirmDeletePopUp) {
			setConfirmDeletePopUp(true);
		} else if (meshIds.length > 0) {
			toastID.current = toast.loading('Deleting selected projects...');
			setLoadingHistory(true);
			const deleteProjects = meshIds.map((meshId) => deleteProject(meshId));

			Promise.all(deleteProjects)
				.then(() => {
					setMeshIds([]);
					setHistory(history.filter((item) => !meshIds.includes(item.id)));
					toast.update(toastID.current, {
						render: 'Selected projects have been deleted.',
						type: 'success',
						autoClose: 3000,
						isLoading: false,
					});
				})
				.finally(() => {
					setConfirmDeletePopUp(false);
					setLoadingHistory(false);
				});
		}
	};

	return (
		<Stack
			spacing={2}
			sx={{
				bgcolor: 'background.default',
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				maxHeight: '100% !important',
				maxWidth: '100vw',
				overflow: 'hidden',
				p: 2,
				position: 'relative',
				scrollBehavior: 'smooth',
				width: '100%',
			}}
		>
			<Box
				sx={{
					position: 'sticky',
				}}
			>
				<QueueDisplay history={history} />

				<Divider />
			</Box>

			<Box
				sx={{
					flex: '1 1 auto !important',
				}}
			>
				<Typography
					fontWeight='bolder'
					sx={{
						mb: 1,
					}}
				>
					History
				</Typography>

				<Stack
					direction='row'
					justifyContent='space-between'
					sx={{
						paddingRight: '0.5rem',
						maxWidth: '100%',
					}}
				>
					<Grid alignItems='center' container direction='row' justifyContent='center' spacing={1}>
						<Grid item xs={5}>
							<Filter
								onFilterChange={(filterBy) => {
									setCurrentFilter(filterBy);
								}}
							/>
						</Grid>

						<Grid item xs={5}>
							<Sorting onSortingChange={setCurrentSorting} />
						</Grid>

						<Grid
							item
							sx={{
								height: '100%',
							}}
							xs={2}
						>
							<IconButton
								aria-label='Toggle grid view or column view'
								onClick={() => {
									setGridView(!gridView);
								}}
								sx={{
									alignItems: 'center',
									backgroundColor: (theme) => theme.palette.background.search,
									border: (theme) => `1px solid ${theme.palette.background.border}`,
									borderRadius: '20px',
									cursor: 'pointer',
									display: 'flex',
									fontSize: '1rem',
									height: '100%',
									marginRight: 1,
									maxHeight: '100%',
									py: 0.3,
									width: '100%',
								}}
							>
								{gridView ? <SplitscreenIcon /> : <GridViewIcon />}
							</IconButton>
						</Grid>
					</Grid>
				</Stack>

				{loadingHistory ? (
					<Box mt={6} pb={4} position='relative'>
						<OverlayLoading />
					</Box>
				) : history?.length > 0 ? (
					<>
						{meshIds.length > 0 && (
							<Grid
								container
								my={1}
								sx={{
									flexDirection: { xs: 'column', sm: 'row' },
								}}
							>
								<Grid
									alignItems='center'
									container
									direction='row'
									item
									md={12}
									spacing={1}
									sx={{
										justifyContent: 'flex-start',
									}}
									xs={12}
								>
									<Grid
										alignItems='center'
										item
										sm={6}
										style={{ display: 'flex', flexDirection: 'row' }}
										xs={6}
									>
										<Radio
											checked={meshIds.length === history.length}
											checkedIcon={<CheckCircleIcon />}
											onClick={toggleSelectAll}
										/>
										<Typography>
											Select All (
											{`${meshIds.length > 0 ? `${meshIds.length}/` : ''}${history.length}`})
										</Typography>
									</Grid>
									<Grid item sm={3} xs={5}>
										<Tooltip arrow describeChild title='Delete selected projects'>
											<Button
												aria-label='Delete projects'
												color='info'
												onClick={handleDeleteProjects}
												startIcon={<DeleteIcon />}
												sx={{
													backgroundColor: 'background.default',
													border: (theme) => `1px solid ${theme.palette.background.border}`,
													borderRadius: '12px',
													'&:hover': {
														backgroundColor: (theme) => theme.palette.original.main,
														color: (theme) => theme.palette.background.default,
													},
												}}
												variant='darker'
											>
												Delete
											</Button>
										</Tooltip>
									</Grid>
								</Grid>
							</Grid>
						)}

						<Box
							sx={{
								height: '100%',
								maxHeight: 'calc(100vh - 18.5rem)',
								overflowY: 'auto',
								scrollBehavior: 'smooth',
							}}
						>
							<Grid
								alignItems='flex-start'
								container
								direction={!gridView ? 'column' : 'row'}
								justifyContent='flex-start'
								spacing={1}
								sx={{
									padding: '0.5rem 0.5rem  0.5rem 0',
								}}
							>
								{customHistoryFilter(
									history.sort(
										(
											a: {
												downloaded: boolean;
												projectTitle: any;
												id: any;
												createdAt: number;
											},
											b: {
												downloaded?: boolean;
												projectTitle: any;
												id: any;
												createdAt: number;
											},
										) => {
											// Sort by downloaded status when 'Downloads' option is selected in the filter
											if (currentFilter.includes('downloads') && (a.downloaded || b.downloaded)) {
												return 0;
											}

											const titleA = (a?.projectTitle || a.id || '').toLowerCase();
											const titleB = (b?.projectTitle || b.id || '').toLowerCase();

											if (currentSorting === 'Oldest') {
												return a.createdAt - b.createdAt;
											}
											if (currentSorting === 'Alphabetical (A-Z)') {
												return titleA.localeCompare(titleB);
											}
											if (currentSorting === 'Alphabetical (Z-A)') {
												return titleB.localeCompare(titleA);
											}

											return b.createdAt - a.createdAt;
										},
									),
									currentFilter,
								).map((item) => (
									<HistoryCard
										key={item.id}
										data={item}
										downloaded={item.downloaded !== undefined}
										gridView={gridView}
										isSelected={false}
										onClick={async () => {
											setProjectData(
												matchBucketToPostgresDataFormat(item, currentUser ?? undefined),
											);

											router.push(`/generate/history/${item.id}`);
										}}
										thumbnailUrl={
											item?.steps?.find(
												(step: { stage: string; status: string }) =>
													step.stage === 'render' && step.status === 'complete',
											)?.outputFile
										}
										title={item?.projectTitle || item?.id}
									/>
								))}
							</Grid>
						</Box>
					</>
				) : (
					<ImageInfo imageUrl='/RemixHistoryImage.png'>
						{currentUser ? (
							<>
								Your AI Remix history will appear here. <br />
							</>
						) : (
							<Button
								aria-label='Login to view your AI Remix history'
								data-cypress='LoginToViewRemixHistory'
								onClick={async () => {
									await signIn('auth0');
								}}
								variant='text'
							>
								Login to view your AI Remix history.
							</Button>
						)}
					</ImageInfo>
				)}
			</Box>

			<ConfirmDelete
				currentUser={currentUser}
				deleteFunction={handleDeleteProjects}
				isOpen={confirmDeletePopUp}
				onClose={() => {
					setConfirmDeletePopUp(false);
				}}
				projectTitle='all selected projects'
				sourceType=''
			/>
		</Stack>
	);
}
