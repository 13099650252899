import { UIHelper } from '@mpx-sdk/helpers/ui';
import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import { isEmpty } from 'lodash';
import { type ReactElement } from 'react';

interface TooltipChipsProps {
	chips: string[];
	onClick?: (chip: string) => void;
}

export default function TooltipChips({ chips, onClick }: TooltipChipsProps): ReactElement | null {
	const responsiveView = UIHelper.isResponsiveView();

	const handleChipClick = (chip: string) => {
		onClick?.(chip);
	};

	return !isEmpty(chips) ? (
		<Box>
			{chips.map((chip) => (
				<Chip
					key={chip}
					color={responsiveView ? 'secondary' : 'default'}
					label={chip}
					onClick={() => {
						handleChipClick(chip);
					}}
					sx={{
						backgroundColor: 'background.chip',
						border: '1px solid transparent',
						borderRadius: '6px',
						margin: '5px 3px',
						padding: '16px 9px',
						transition: 'all 0.2s ease-in-out',
						'&:hover': {
							backgroundColor: 'background.cards',
							border: (theme) => `1px solid ${theme.palette.primary.main}`,
						},
					}}
					variant='filled'
				/>
			))}
		</Box>
	) : null;
}
