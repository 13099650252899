import { getBalanceByCurrency, getMyBalance } from '@mpx-sdk/helpers/economy';
import { isWordMature } from '@mpx-sdk/helpers/input';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { BackIcon, CreditsIcon, RemixAIIcon } from '@mpx-sdk/images';
import {
	activeStepAtom,
	availableCreditsAtom,
	flagFlashMessageAtom,
	forcefullyCloseTooltipAtom,
	inAppBrowserAtom,
	userAtom,
	userHistoryAtom,
} from '@mpx-sdk/shared/atoms';
import { MLTypes, urls } from '@mpx-sdk/shared/configs';
import { GenModelConsts, GenerateMode, type MLSnapshotData, MeshType, StepNames } from '@mpx-sdk/types';
import { useDialogService } from '@mpx-sdk/ui/components/core/DialogService';
import BuyMoreCredit from '@mpx-sdk/ui/components/purchase/BuyMoreCredit';
import MLProcessingDoc from '@mpx/app/generate/models/MLProcessingDoc';
import MLSession from '@mpx/app/generate/models/MLSession';
import HistoryDrawer from '@mpx/components/generate/history/HistoryDrawer';
import { OnboardingDialog } from '@mpx/components/generate/onboarding';
import ProjectTitle from '@mpx/components/generate/text-to-3d/ProjectTitle';
import AnimateStep from '@mpx/components/generate/text-to-3d/steps/AnimateStep';
import PaintStep from '@mpx/components/generate/text-to-3d/steps/PaintStep';
import ResultsStep from '@mpx/components/generate/text-to-3d/steps/ResultsStep';
import ShapeStep from '@mpx/components/generate/text-to-3d/steps/ShapeStep';
import StartStep from '@mpx/components/generate/text-to-3d/steps/StartStep';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Divider,
	IconButton,
	Stack,
	Step,
	StepLabel,
	Stepper,
	SwipeableDrawer,
	Switch,
	Tooltip,
	Typography,
} from '@mui/material';
import { onSnapshot } from 'firebase/firestore';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { find, findIndex, isEmpty } from 'lodash';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { type ReactElement, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { toast } from 'react-toastify';
import OnePromptTextTo3D from './OnePrompt';

export const steps = [
	{ name: StepNames.START, component: StartStep, order: 0, buttonText: 'Next Step: Add Mesh Details' },
	{ name: StepNames.SHAPE, component: ShapeStep, order: 1, buttonText: 'Next Step: Continue to Texture & Color' },
	{ name: StepNames.PAINT, component: PaintStep, order: 2, buttonText: 'Next Step: Add Animation' },
	{ name: StepNames.ANIMATE, component: AnimateStep, order: 3, buttonText: 'Generate Model(s) ' },
	{ name: StepNames.RESULTS, component: ResultsStep, order: 4 },
];

export const getMeshTypeByVariability = (modelGenerationIndex: number, variability: number, meshType: MeshType) => {
	if (meshType === MeshType.OBJECT) {
		meshType = MeshType.ANIMAL;
	}

	const data = [
		{ type: MeshType.ANIMAL, variability: 0, autoSelect: [1, 2, 3, 4], meshGen: [] },
		{ type: MeshType.ANIMAL, variability: 1, autoSelect: [1, 2, 3, 4], meshGen: [] },
		{ type: MeshType.ANIMAL, variability: 2, autoSelect: [1, 3], meshGen: [2, 4] },
		{ type: MeshType.ANIMAL, variability: 3, autoSelect: [2, 4], meshGen: [1, 3] },
		{ type: MeshType.ANIMAL, variability: 4, autoSelect: [], meshGen: [1, 2, 3, 4] },
		{ type: MeshType.HUMAN, variability: 0, autoSelect: [1, 2, 3, 4], meshGen: [] },
		{ type: MeshType.HUMAN, variability: 1, autoSelect: [1, 2, 3, 4], meshGen: [] },
		{ type: MeshType.HUMAN, variability: 2, autoSelect: [1, 2, 3, 4], meshGen: [] },
		{ type: MeshType.HUMAN, variability: 3, autoSelect: [1, 3], meshGen: [2, 4] },
		{ type: MeshType.HUMAN, variability: 4, autoSelect: [], meshGen: [1, 2, 3, 4] },
	];

	const dataForVariability = data.filter((d) => d.variability === variability);
	const dataForMeshType = dataForVariability.filter((d) => d.type === meshType);

	if (find(dataForMeshType[0].autoSelect, (a) => a === modelGenerationIndex)) return 'autoSelect';

	if (find(dataForMeshType[0].meshGen, (a) => a === modelGenerationIndex)) return 'generateMesh';

	return null;
};

export default function TextTo3D(): ReactElement {
	const router = useRouter();

	const [activeStep, setActiveStep] = useAtom(activeStepAtom);
	const currentUser = useAtomValue(userAtom);
	const [mode, setMode] = useState<GenerateMode>(GenerateMode.BASIC);
	const [enableResultsStep, setEnableResultsStep] = useState(false);
	const [openHistoryDrawer, setOpenHistoryDrawer] = useState<boolean>(Boolean(currentUser));
	const availableCredits = useAtomValue(availableCreditsAtom);
	const containerRef = useRef(null);
	const forcefullyCloseTooltip = useSetAtom(forcefullyCloseTooltipAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const isAnimateStep = activeStep === StepNames.ANIMATE;
	const isPaintStep = activeStep === StepNames.PAINT;
	const responsiveView = UIHelper.isResponsiveView();
	const userHistory = useAtomValue(userHistoryAtom);
	const { showDialog } = useDialogService();
	const flagFlash = useAtomValue(flagFlashMessageAtom);

	const handleNext = (e: { preventDefault: () => void }) => {
		forcefullyCloseTooltip(true);

		e.preventDefault();
		const currentStep = findIndex(steps, { name: activeStep });
		if (currentStep < steps.length - 1) {
			setActiveStep(steps[currentStep + 1].name);
		}
	};

	const handleSubmit = async ({
		projectTitle,
		skipAnimate,
		skipPaint,
		modelsToGenerate,
		meshVariability,
		animationType,
		...submission
	}: any) => {
		if (!currentUser) {
			toast.error('Please login to generate models.');

			return null;
		}

		forcefullyCloseTooltip(true);

		delete submission.source;
		const session = new MLSession();

		// Check if any of looksLikePrompt, movesLikePrompt, promptNeg and promptPos contain any mature words
		const isMature = {
			shape: isWordMature(projectTitle),
			'looks like': isWordMature(submission.looksLikePrompt),
			'moves like': isWordMature(submission.movesLikePrompt),
			'paint does not look like': isWordMature(submission.promptNeg),
			'paint look like': isWordMature(submission.promptPos),
		};
		// Check if any of the isMature values are true and if so, show error message of where the mature word is located and return null
		if (Object.values(isMature).some((value) => value)) {
			const matureWords = Object.keys(isMature).filter((key) => isMature[key]);
			toast.error(`Please remove any mature words from your ${matureWords.join(', ')} prompts.`);

			return null;
		}

		const promptPos = submission.promptPos ? `, ${submission.promptPos}` : '';

		try {
			const documents = await Promise.all(
				Array(modelsToGenerate)
					.fill('')
					.map((_val, index) =>
						new MLProcessingDoc(
							{
								data: {
									animate: submission.meshType === MeshType.HUMAN && animationType !== 'none',
									rig_only: animationType === 'rig_only',
									paint: !skipPaint,
									...submission,
									category: `${submission.looksLikePrompt}`,
									looksLikePrompt: `${submission.looksLikePrompt}`,
									promptPos: `${submission.looksLikePrompt}${promptPos}`,
									meshVariability,
									meshSelection: getMeshTypeByVariability(
										index + 1,
										meshVariability,
										submission.meshType,
									),
								},
								projectTitle: `${submission?.looksLikePrompt?.replace(/ /g, '_')}_${index + 1}`,
								type: MLTypes.MESH_TO_ANIMATION,
							},
							session,
						).save(),
					),
			);

			getMyBalance(); // Update balance asynchronously

			// Check if user has enough credits to generate models
			// const generationCredits = getBalanceByCurrency('credits', availableCredits);
			// if (!generationCredits || generationCredits < modelsToGenerate) {
			// 	throw new Error('Not enough credits');
			// }
			toast.success(
				`Generating your model(s) now... \n This may take ${GenModelConsts.estimatedTimeValue} for each model. You can see the models in history panel`,
			);
			documents.forEach((document: any) => {
				const unsubscribe = onSnapshot(document.ref, (doc: any) => {
					const data: any = { ...(doc.data() as MLSnapshotData), id: doc.id };

					data.unsubscribe = unsubscribe;

					if (data.status === 'complete') {
						unsubscribe();
					}
				});
			});

			setEnableResultsStep(true);
			setActiveStep(StepNames.RESULTS);

			DataLayer.triggerMeasurementEvent('generateEvent', {
				event_name: 'generate_model',
				in_app: inApp,
				user_id: currentUser?.id,
				user_number_of_created_models: userHistory?.length ?? 0,
				success: true,
				mesh_variability: meshVariability,
				models_to_generate: modelsToGenerate,
				project_title: projectTitle,
				skip_animate: skipAnimate,
				skip_paint: skipPaint,
				available_credits: getBalanceByCurrency('credits', availableCredits),
				...submission,
			});
		} catch (err: any) {
			const balanceCredits = getBalanceByCurrency('credits', availableCredits);

			if (err?.cause?.status === 402 || !balanceCredits || balanceCredits < modelsToGenerate * 50) {
				showDialog(BuyMoreCredit.getAsDialog());
			} else {
				console.error('Error while generating', err);
				toast.error('There was an error generating your model(s). If this persists, please contact support.');
			}

			DataLayer.triggerMeasurementEvent('generateEvent', {
				event_name: 'generate_model',
				user_id: currentUser?.id,
				user_number_of_created_models: userHistory?.length ?? 0,
				success: false,
				mesh_variability: meshVariability,
				models_to_generate: modelsToGenerate,
				project_title: projectTitle,
				skip_animate: skipAnimate,
				skip_paint: skipPaint,
				...submission,
				available_credits: getBalanceByCurrency('credits', availableCredits),
			});
		}
	};

	const isNextStepDisabled = (form: any) => {
		if (activeStep === StepNames.SHAPE) {
			return isEmpty(form.getFieldState('looksLikePrompt')?.value);
		}

		if (activeStep === StepNames.PAINT) {
			return isEmpty(form.getFieldState('promptPos')?.value);
		}

		if (activeStep === StepNames.ANIMATE) {
			return (
				form.getFieldState('animationType')?.value === 'rig_animation' &&
				isEmpty(form.getFieldState('movesLikePrompt')?.value)
			);
		}

		return false;
	};

	return (
		<>
			{/* Create switch for ON/OFF */}
			<Stack
				direction='row'
				sx={{
					height: '100%',
					overflowX: 'hidden',
					transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms',
					width: '100%',
				}}
			>
				<Box
					bgcolor='background.default'
					position='relative'
					sx={{
						alignItems: 'flex-start',
						display: 'flex',
						justifyContent: 'center',
						transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms',
						width: '100%',
					}}
				>
					<Box
						ref={containerRef}
						sx={{
							border: responsiveView ? (theme) => `1px solid ${theme.palette.borders.dark}` : 'none',
							borderRadius: '16px',
							margin: '1.5rem 0',
							minWidth: responsiveView ? 'min(100%, 510px)' : '100%',
							maxWidth: responsiveView ? '510px' : '100%',
						}}
						width='100%'
					>
						{activeStep !== StepNames.RESULTS && (
							<Stack
								alignItems='center'
								direction='row'
								justifyContent='center'
								mt={2}
								mx='auto'
								spacing={1}
							>
								<Typography>{GenerateMode.BASIC}</Typography>
								<Switch
									defaultChecked={false}
									onChange={(e) => {
										setMode(e.target.checked ? GenerateMode.ADVANCED : GenerateMode.BASIC);
										setActiveStep(StepNames.START); // Reset to start step
									}}
								/>
								<Typography>{GenerateMode.ADVANCED}</Typography>
							</Stack>
						)}

						{mode === GenerateMode.BASIC && <OnePromptTextTo3D />}

						{mode === GenerateMode.ADVANCED && (
							<Form
								initialValues={{
									animationType: 'none',
									meshSelection: 'autoGenerate',
									meshType: MeshType.OBJECT,
									meshVariability: 2,
									modelsToGenerate: 2,
									projectTitle: 'Untitled Model Project 1',
									sigma: 1.5,
									source: 'library',
								}}
								onSubmit={handleSubmit}
							>
								{({ handleSubmit, form, submitting }) => (
									<Stack
										component='form'
										onSubmit={
											(
												form.getFieldState('meshType')?.value === MeshType.HUMAN
													? !isAnimateStep
													: !isPaintStep
											)
												? handleNext
												: handleSubmit
										}
										spacing={1}
										sx={{
											display: 'flex',
											height: '100%',
										}}
										width='100%'
									>
										<Box display='none'>
											<ProjectTitle />
										</Box>

										{!responsiveView && (
											<>
												<OnboardingDialog />

												<Divider
													sx={{
														margin: '3% auto !important',
														width: '85%',
													}}
													variant='middle'
												/>
											</>
										)}

										{activeStep !== StepNames.RESULTS && (
											<>
												<Stepper
													activeStep={findIndex(steps, { name: activeStep })}
													alternativeLabel
													sx={{
														position: 'relative',
														transition: 'all 0.5s ease-in-out',
														pt: 1,
														'.MuiStepLabel-label': {
															transition: 'all 0.5s ease-in-out',
															marginTop: '5%',
															'.MuiTypography-root': {
																fontSize: 'clamp(0.5rem, 0.75rem, 1rem)',
															},
														},
														'.Mui-completed': {
															transition: 'all 0.5s ease-in-out',
															color: (theme) =>
																`${theme.palette.primary.light} !important`,
															'.MuiStepConnector-line': {
																borderColor: (theme) =>
																	`${theme.palette.primary.light} !important`,
															},
														},
														'.Mui-active': {
															transition: 'all 0.5s ease-in-out',
															color: '#00E7AF !important',
															'.MuiStepConnector-line': {
																borderColor: '#00E7AF !important',
															},
														},
														'.MuiStepIcon-root': {
															fontSize: '1.75rem !important',
														},
														'.MuiStepConnector-root': {
															'.MuiStepConnector-line': {
																height: 3,
																border: 0,
																borderRadius: 1,
																transition: 'all 0.5s ease-in-out',
																borderTopWidth: '3px !important',
															},
														},
													}}
												>
													{steps.map(({ name: label }) => {
														const isSelectedHuman =
															form.getFieldState('meshType')?.value === MeshType.HUMAN;

														if (label === StepNames.ANIMATE && !isSelectedHuman) {
															return null;
														}

														const isStepActive = label === activeStep;

														return (
															<Step key={`step-${label}`}>
																<StepLabel
																	onClick={() => {
																		forcefullyCloseTooltip(true);

																		if (
																			label === StepNames.RESULTS &&
																			!enableResultsStep
																		) {
																			return;
																		}

																		if (
																			![
																				StepNames.START,
																				StepNames.SHAPE,
																			].includes(label) &&
																			isEmpty(
																				form.getFieldState('looksLikePrompt')
																					?.value,
																			)
																		) {
																			setActiveStep(StepNames.SHAPE);
																		} else {
																			setActiveStep(label);
																		}
																	}}
																	sx={{
																		zIndex: 1,
																		display: 'flex',
																		borderRadius: '50%',
																		justifyContent: 'center',
																		alignItems: 'center',
																		textTransform: isStepActive
																			? 'uppercase'
																			: 'capitalize',
																		'& .MuiStepLabel-label': {
																			color: isStepActive ? '#00E7AF' : '#BEBEBE',
																		},
																	}}
																>
																	<Typography variant='caption'>{label}</Typography>
																</StepLabel>
															</Step>
														);
													})}
												</Stepper>

												<Divider
													sx={{
														margin: '3% auto !important',
														width: '85%',
													}}
													variant='middle'
												/>
											</>
										)}

										<Box
											sx={{
												display: 'flex',
												flex: 1,
												height: '100%',
											}}
										>
											{steps.map(({ component: StepComponent, name, buttonText }) => {
												if (
													name === StepNames.PAINT &&
													form?.getFieldState('meshType')?.value !== MeshType.HUMAN
												) {
													buttonText = GenModelConsts.generate3DModel;
												}

												return (
													<Stack
														key={`step-${name}`}
														display={name === activeStep ? 'flex' : 'none'}
														px={2}
														spacing={3}
														sx={{
															pt: 2,
															p: '12px',
															margin: '1% 3% !important',
															width: '100%',
															flex: 1,
														}}
													>
														<StepComponent />

														{/* Show Credits Cost */}
														{activeStep === StepNames.START && (
															<>
																<Divider />

																<Stack
																	direction='column'
																	spacing={1}
																	sx={{
																		margin: 'auto 5% !important',
																		pt: 2,
																		pb: 2,
																	}}
																>
																	<Stack
																		alignItems='center'
																		direction='row'
																		justifyContent='space-between'
																		sx={{
																			pt: 2,
																		}}
																	>
																		<Typography>
																			{GenModelConsts.creditCost}
																		</Typography>
																		<Stack
																			alignItems='center'
																			direction='row'
																			spacing={1}
																		>
																			<CreditsIcon />
																			<Box>
																				{GenModelConsts.oneModelCredit *
																					(form?.getFieldState?.(
																						'modelsToGenerate',
																					)?.value ?? 1)}{' '}
																				{GenModelConsts.credits}
																			</Box>
																		</Stack>
																	</Stack>

																	<Stack
																		alignItems='center'
																		direction='row'
																		justifyContent='space-between'
																	>
																		<Typography>
																			{GenModelConsts.estimatedTime}
																		</Typography>

																		<Typography>
																			{GenModelConsts.estimatedTimeValue}
																		</Typography>
																	</Stack>
																</Stack>
															</>
														)}

														{/* CTA */}
														<Stack
															alignItems='center'
															direction='column'
															justifyContent='center'
														>
															{buttonText && (
																<LoadingButton
																	data-cypress='text-to-3d-next-stepper'
																	disabled={isNextStepDisabled(form)}
																	loading={submitting}
																	onClick={async (e) => {
																		forcefullyCloseTooltip(true);

																		if (!currentUser) {
																			e.preventDefault();

																			if (inApp) {
																				router.push(urls.routes.deviceLogin);
																			} else {
																				await signIn('auth0');
																			}
																		}
																	}}
																	sx={{
																		background: (theme) =>
																			isNextStepDisabled(form)
																				? theme.palette.primary.dark
																				: theme.palette.gradient.main,
																		backgroundColor: 'original.main',
																		borderRadius: '100px',
																		fontSize: 'clamp(0.5rem, 1rem, 1rem)',
																		fontWeight: '700',
																		letterSpacing: '0.0025em',
																		lineHeight: '32px',
																		width: '100%',
																		'&:hover': {
																			background: (theme) =>
																				theme.palette.gradient.hover,
																		},
																	}}
																	type='submit'
																	variant='contained'
																>
																	{currentUser && buttonText.includes('Generate') && (
																		// eslint-disable-next-line @next/next/no-img-element
																		<RemixAIIcon
																			sx={{
																				width: '20px',
																				height: '20px',
																				margin: '1% 2.5%',
																			}}
																		/>
																	)}
																	{currentUser
																		? buttonText
																		: GenModelConsts.loginToGenerate}
																</LoadingButton>
															)}

															{activeStep !== StepNames.START &&
																activeStep !== StepNames.RESULTS && (
																	<IconButton
																		aria-label={`Back to step ${
																			steps[
																				findIndex(steps, {
																					name: activeStep,
																				}) - 1
																			].name
																		}`}
																		disableRipple
																		onClick={() => {
																			forcefullyCloseTooltip(true);

																			const currentStepIndex = findIndex(steps, {
																				name: activeStep,
																			});
																			if (currentStepIndex > 0) {
																				setActiveStep(
																					steps[currentStepIndex - 1].name,
																				);
																			}
																		}}
																		sx={{
																			color: 'text.disabled',
																			marginTop: '0.5rem',
																			'&:hover': {
																				color: 'primary.main',
																			},
																		}}
																	>
																		<ArrowBackIosIcon />
																		<Typography>
																			Back:{' '}
																			{
																				steps[
																					findIndex(steps, {
																						name: activeStep,
																					}) - 1
																				].name
																			}{' '}
																		</Typography>
																	</IconButton>
																)}
														</Stack>
													</Stack>
												);
											})}
										</Box>
									</Stack>
								)}
							</Form>
						)}
					</Box>
				</Box>

				<SwipeableDrawer
					anchor='right'
					disableBackdropTransition
					onClose={() => setOpenHistoryDrawer(false)}
					onOpen={() => setOpenHistoryDrawer(true)}
					open={responsiveView && openHistoryDrawer}
					sx={{
						display: openHistoryDrawer && responsiveView ? 'block' : 'none',
						flexShrink: 0,
						height: '100%',
						marginTop: '0',
						maxWidth: 'max(27%, 420px)',
						opacity: openHistoryDrawer && responsiveView ? 1 : 0,
						padding: '0 !important',
						position: openHistoryDrawer && responsiveView ? 'sticky' : 'none',
						transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms',
						width: '100%',
						[`& .MuiDrawer-paper`]: {
							backgroundColor: 'background.default',
							backgroundImage: 'none',
							borderLeft: (theme) => `1px solid ${theme.palette.borders.dark}`,
							top: 'initial',
							borderRadius: '0',
							maxHeight: '100%',
							maxWidth: 'max(27%, 420px)',
							overflowY: 'hidden',
							padding: '0 !important',
							width: '100%',
						},
						'.MuiPaper-root': {
							padding: '0 !important',
						},
					}}
					variant='persistent'
				>
					<Box
						sx={{
							width: '100%',
							padding: 0,
						}}
					>
						<HistoryDrawer />
					</Box>
				</SwipeableDrawer>
			</Stack>
			{/* Icon to close or open history drawer */}
			<Tooltip
				arrow
				describeChild
				placement='left'
				title={`${openHistoryDrawer ? 'Close' : 'Open'} History Panel`}
			>
				<IconButton
					aria-label={`${openHistoryDrawer ? 'Close' : 'Open'} History Panel`}
					onClick={() => setOpenHistoryDrawer(!openHistoryDrawer)}
					sx={{
						backgroundColor: 'icons.drawer',
						borderBottom: (theme) => `1px solid ${theme.palette.icons.drawer}`,
						borderLeft: (theme) => `1px solid ${theme.palette.icons.drawer}`,
						borderRadius: '5px 0 0 5px',
						borderRight: 'none',
						borderTop: (theme) => `1px solid ${theme.palette.icons.drawer}`,
						flexShrink: 0,
						height: '63px',
						opacity: responsiveView ? 1 : 0,
						position: 'fixed',
						right: openHistoryDrawer && responsiveView ? 'max(27%, 420px)' : '0',
						top: flagFlash ? '158px' : '108px',
						transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms',
						width: '22px',
						zIndex: (theme) => theme.zIndex.drawer + 1,
						'&:hover': {
							backgroundColor: 'icons.drawer',
							borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
							borderLeft: (theme) => `1px solid ${theme.palette.primary.main}`,
							borderRight: 'none',
							borderTop: (theme) => `1px solid ${theme.palette.primary.main}`,
							opacity: responsiveView ? 1 : 0,
						},
					}}
				>
					<BackIcon
						sx={{
							transform: openHistoryDrawer ? 'rotate(180deg)' : 'rotate(0deg)',
						}}
					/>
				</IconButton>
			</Tooltip>
		</>
	);
}
