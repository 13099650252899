import { UIHelper } from '@mpx-sdk/helpers/ui';
import { MeshType } from '@mpx-sdk/types';
import FormField from '@mpx-sdk/ui/components/core/form/FormField';
import IconTooltip from '@mpx-sdk/ui/components/tooltips/IconTooltip';
import TooltipChips from '@mpx/components/generate/text-to-3d/TooltipChips';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { Field, useForm } from 'react-final-form';

const PreferenceStyles = {
	textAlign: 'center',
	margin: 'auto',
	span: {
		fontWeight: 'bold',
		fontSize: '0.8rem',
	},
	fontSize: '1rem',
};

const ResultPreference = [
	{
		value: 0,
		label: (
			<Typography sx={PreferenceStyles} variant='caption'>
				<span>More</span> <br /> Precise
			</Typography>
		),
	},
	{
		value: 2,
		label: (
			<Typography sx={PreferenceStyles} variant='caption'>
				<span>More</span> <br /> Balanced{' '}
			</Typography>
		),
	},
	{
		value: 4,
		label: (
			<Typography sx={PreferenceStyles} variant='caption'>
				<span>More</span> <br /> Creative
			</Typography>
		),
	},
];

const autofillOptions: Record<string, string[]> = {
	animal: ['Bird', 'Cat', 'Dog', 'Male Lion', 'Frog', 'Horse', 'Zebra', 'Bear', 'Lizard', 'Chicken', 'Whale Shark'],
	human: [
		'Viking',
		'Pirate',
		'Policeman',
		'Astronaut',
		'Soldier',
		'Princess',
		'Athletic Male Superhero',
		'Cowboy',
		'Strong Female Warrior',
		'Wizard',
	],
	object: [
		'Apple',
		'Axe',
		'Basketball',
		'Book',
		'Treasure Chest',
		'Bread',
		'Lantern',
		'Bottle of Wine',
		'Wooden Barrel',
		'Knights Helmet',
		'Magic Playing Cards',
	],
};

const limitations = {
	[MeshType.HUMAN]: [
		'Currently only English language words are supported',
		'These are prototype models - they may exhibit some quality variation',
		'Specific references (brands, makes, models, logo) are not supported and may not generate at all or as expected',
		'Remember to have fun exploring & being inspired by your creative helper/intern!',
	],
	[MeshType.ANIMAL]: [
		'English only',
		'Generate ONE animal at a time',
		'Some models may exhibit imperfections',
		'Reference to popular characters may not generate as expected',
	],
	[MeshType.OBJECT]: [
		'English only',
		'Generate ONE object at a time',
		'Some models may exhibit imperfections',
		'Reference to popular characters may not generate as expected',
	],
};

export default function ShapeStep() {
	const form = useForm();
	const source = form.getFieldState('source')?.value;

	const responsiveView = UIHelper.isResponsiveView();

	const handleAutofillClick = (text: string) => {
		form.change('looksLikePrompt', `${text}`);
	};

	const getMeshType = () => {
		const meshType = form.getFieldState('meshType')?.value;
		if (meshType === 'human') {
			return 'human';
		}
		if (meshType === 'animal') {
			return 'animal';
		}
		return 'object';
	};

	return (
		<>
			<Typography variant='h6'>Mesh Shape Details</Typography>
			{source === 'library' && (
				<FormGroup>
					<FormControlLabel control={<Checkbox defaultChecked />} label='Keep Shape' />
				</FormGroup>
			)}

			<Stack spacing={1}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography>What are you imagining? (2-5 words)</Typography>

					{/* Add the IconTooltip component here */}
					{getMeshType() === MeshType.HUMAN && (
						<IconTooltip
							key='tooltip-shape-step-word-count'
							paragraphs={[
								"Human models won't generate well with accessories included in the prompt.",
								"You can optionally add in your human model's gender for a more specific result.",
							]}
						/>
					)}
				</Box>

				<FormField
					fullWidth
					hideCharacterCount
					interactive
					maxWords={5}
					multiline
					name='looksLikePrompt'
					placeholder='Describe the type of model or shape you’d like to see as best as you can imagine it.'
					rows={4}
					title=''
					tooltip={
						responsiveView
							? {
									title: 'Try These Examples',
									content: [
										<TooltipChips
											key='chipslist1'
											chips={autofillOptions[getMeshType()]}
											onClick={(option) => {
												handleAutofillClick(option);

												if (responsiveView) {
													// click on the form textfield
													const element = document.getElementById('text-looksLikePrompt');
													// Click on the element so that the key can be entered
													element?.click();
												}
											}}
										/>,

										<>
											<div
												key='limitations'
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Image
													alt='Limitations-Alert'
													height={30}
													src='/alert-triangle.svg'
													width={15}
												/>
												&nbsp;
												<Typography fontWeight='bold' variant='body2'>
													Limitations
												</Typography>
											</div>

											<Typography component='ul' variant='caption'>
												{limitations[getMeshType()].map((limitation) => (
													<li key={limitation}>{limitation}</li>
												))}
											</Typography>
										</>,
									],
								}
							: undefined
					}
					variant='outlined'
				/>

				{!responsiveView && (
					<TooltipChips
						key='chipslist1-sub'
						chips={autofillOptions[getMeshType()]}
						onClick={(option) => handleAutofillClick(option)}
					/>
				)}

				<Box>
					<Typography component='div' id='discrete-slider' mb={3} mt={3}>
						Choose your mesh&apos;s style
						<IconTooltip
							key='tooltip-shape-step'
							paragraphs={[
								<Typography key='shape-style-tooltip-1'>
									<b>More Precise</b> creates more realistic and accurate shapes, but is limited in
									the types of shapes it produces.
								</Typography>,
								<Typography key='shape-style-tooltip-2'>
									<b>More Creative</b> has flexibility to produce a more specific shape, but the
									shapes might be lower quality with more unwanted characteristics.
								</Typography>,
							]}
						/>
					</Typography>
					<Field
						name='meshVariability'
						render={({ input }) => (
							<Stack
								direction='row'
								justifyContent='space-between'
								sx={{
									backgroundColor: 'background.text',
									padding: 1,
									border: (theme) => `1px solid ${theme.palette.background.border}`,
									borderRadius: '5px',
								}}
							>
								{/* For each value in ResultPreference, create a button */}
								{ResultPreference.map((value) => (
									<Button
										key={value.value}
										onClick={() => input.onChange(value.value)}
										sx={{
											backgroundColor: input.value === value.value ? 'primary.main' : 'initial',
											color: input.value === value.value ? 'primary.contrastText' : 'white',
											width: '100%',
											height: '100%',
											borderRadius: '5px',
											fontSize: '0.8rem',
											fontWeight: 'bold',
											padding: '0.5rem',
											transition: 'all 0.2s ease-in-out',
											'&:hover': {
												backgroundColor:
													input.value === value.value ? 'primary.light' : 'background.paper',
												color: input.value === value.value ? 'primary.contrastText' : 'white',
											},
										}}
										variant='contained'
									>
										{value.label}
									</Button>
								))}
							</Stack>
						)}
						type='range'
					/>
				</Box>
			</Stack>
		</>
	);
}
