import { UIHelper } from '@mpx-sdk/helpers/ui';
import { ConfettiIcon, HistoryIcon, RemixAIIcon } from '@mpx-sdk/images';
import { activeStepAtom, genAiResponsiveViewAtom, inAppBrowserAtom } from '@mpx-sdk/shared/atoms';
import { GenModelConsts, StepNames } from '@mpx-sdk/types';
import MiddleSectionTitle from '@mpx/app/generate/middle-section/MiddleSectionTitle';
import { Box, Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useAtomValue, useSetAtom } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { type ReactElement } from 'react';
import { useForm } from 'react-final-form';

interface ResultsStepProps {
	/** Function to open the history panel */
	handleOpenHistory?: () => void;
}

export default function ResultsStep({ handleOpenHistory }: ResultsStepProps): ReactElement {
	const form = useForm();
	const inApp = useAtomValue(inAppBrowserAtom);
	const responsiveView = UIHelper.isResponsiveView();
	const router = useRouter();
	const setActiveStep = useSetAtom(activeStepAtom);
	const setGenAiResponsiveViewAtom = useSetAtom(genAiResponsiveViewAtom);

	const handleCreateNew = () => {
		// Reset the form by clearing the values and errors in the form fields
		form.reset();
		setActiveStep(StepNames.START);
	};

	return responsiveView ? (
		<Stack>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<ConfettiIcon
					sx={{
						width: '40px',
						height: '40px',
					}}
				/>
			</Box>

			<Typography mt={2} textAlign='center' variant='h6'>
				{GenModelConsts.resultCardTitle}
			</Typography>
			<Typography mt={2} textAlign='center'>
				Once generation is complete, your <br />
				creations will show in the History Section👉
			</Typography>

			<Stack
				direction='column'
				spacing={1}
				sx={{
					maxWidth: '85%',
					marginLeft: 'auto',
					marginRight: 'auto',
					marginTop: '1.5rem',
					color: 'text.subtext',
					fontSize: 'clamp(0.5rem, 0.9rem, 0.9rem)',
				}}
			>
				<Typography mt={2} textAlign='left'>
					While you wait:
				</Typography>

				<ul>
					<li>
						Check out what everyone&apos;s creating and remixing in our{' '}
						<Link
							href='/library'
							shallow
							style={{
								color: '#00EAB7',
								textDecoration: 'underlined',
							}}
						>
							Community Library
						</Link>
					</li>
					<br />
					<li>Listen to some music and do a happy dance with all the time you saved creating 3D models!</li>
					<br />
					<li>Share your generated models to the Community Library for others to remix!</li>
				</ul>
			</Stack>

			<Stack
				spacing={3}
				sx={{
					marginTop: '2rem',
				}}
			>
				<Button
					aria-label='Generate new 3D asset'
					fullWidth
					onClick={handleCreateNew}
					sx={{
						background: (theme) => theme.palette.gradient.main,
						backgroundColor: 'original.main',
						borderRadius: '100px',
						fontSize: 'clamp(0.5rem, 1rem, 1rem)',
						fontWeight: '700',
						letterSpacing: '0.0025em',
						lineHeight: '32px',
						alignItems: 'center',
						transition: 'all 0.2s ease-in-out',
						mt: 2,
						'&:hover': {
							background: (theme) => theme.palette.gradient.hover,
						},
					}}
					variant='contained'
				>
					<RemixAIIcon
						sx={{
							width: '20px',
							height: '20px',
							margin: '1% 2.5%',
						}}
					/>{' '}
					{GenModelConsts.resultCardGenerateBtn}
				</Button>

				<Button
					aria-label='View your history generated 3D assets'
					fullWidth
					onClick={() => {
						if (inApp) {
							router.push('/storage?tab=my-generations', {
								shallow: true,
							});
						} else if (handleOpenHistory) {
							handleOpenHistory();
						} else {
							router.push('/generate/history', {
								shallow: true,
							});
						}
					}}
					sx={{
						backgroundColor: 'background.chip',
						borderRadius: '100px',
						fontSize: 'clamp(0.5rem, 1rem, 1rem)',
						fontWeight: '700',
						letterSpacing: '0.0025em',
						lineHeight: '32px',
						alignItems: 'center',
						mt: 2,
						color: 'text.primary',
						border: '1px solid transparent',
						transition: 'all 0.2s ease-in-out',
						'&:hover': {
							backgroundColor: 'background.cards',
							border: (theme) => `1px solid ${theme.palette.primary.main}`,
						},
					}}
					variant='contained'
				>
					<HistoryIcon
						sx={{
							width: '20px',
							height: '20px',
							margin: '1% 2.5%',
						}}
					/>{' '}
					{GenModelConsts.resultCardHistoryBtn}
				</Button>
			</Stack>
		</Stack>
	) : (
		<Stack margin={{ xs: 0.5, lg: 6 }}>
			<Stack
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					textAlign: 'center',
					mt: 0.5,
				}}
			>
				<MiddleSectionTitle type='title'>{GenModelConsts.resultCardTitle}</MiddleSectionTitle>
				<MiddleSectionTitle type='subtitle'>
					You&apos;ll see them in your {inApp ? 'Assets page by clicking on the History' : 'History'} tab
					below 👇
				</MiddleSectionTitle>
			</Stack>

			{!inApp ?? <MiddleSectionTitle type='heading'>While You Wait</MiddleSectionTitle>}

			<Stack spacing={2}>
				<Button
					aria-label='Generate another 3D asset'
					onClick={handleCreateNew}
					sx={{
						background: (theme) => theme.palette.gradient.main,
						backgroundColor: 'original.main',
						borderRadius: '100px',
						fontSize: 'clamp(0.5rem, 1rem, 1rem)',
						fontWeight: '700',
						letterSpacing: '0.0025em',
						lineHeight: '32px',
						'&:hover': {
							background: (theme) => theme.palette.gradient.hover,
						},
					}}
					type='submit'
					variant='contained'
				>
					{inApp ? (
						<>
							<RemixAIIcon
								sx={{
									margin: '1%',
								}}
							/>
							Generate More 3D Models
						</>
					) : (
						'Generate Another 3D Model'
					)}
				</Button>

				{!inApp && (
					<>
						<Button
							aria-label='View community library'
							onClick={() => {
								setGenAiResponsiveViewAtom('gallery-display');
							}}
							sx={{
								background: (theme) => theme.palette.gradient.main,
								backgroundColor: 'original.main',
								borderRadius: '100px',
								fontSize: 'clamp(0.5rem, 1rem, 1rem)',
								fontWeight: '700',
								letterSpacing: '0.0025em',
								lineHeight: '32px',
								'&:hover': {
									background: (theme) => theme.palette.gradient.hover,
								},
							}}
							type='submit'
							variant='contained'
						>
							View Other Generated 3D Models
						</Button>

						<Button
							aria-label='View history'
							onClick={() => {
								setGenAiResponsiveViewAtom('history-display');
							}}
							sx={{
								background: (theme) => theme.palette.gradient.main,
								backgroundColor: 'original.main',
								borderRadius: '100px',
								fontSize: 'clamp(0.5rem, 1rem, 1rem)',
								fontWeight: '700',
								letterSpacing: '0.0025em',
								lineHeight: '32px',
								'&:hover': {
									background: (theme) => theme.palette.gradient.hover,
								},
							}}
							type='submit'
							variant='contained'
						>
							View Progress and History
						</Button>
					</>
				)}
			</Stack>
		</Stack>
	);
}
