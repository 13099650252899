import IconTooltip from '@mpx-sdk/ui/components/tooltips/IconTooltip';
import { Skeleton, Stack, Typography } from '@mui/material';
import React, { type ReactElement, useEffect } from 'react';
import useQueueStatus from './qStatusHook';

interface QueueDisplayProps {
	/** The history of requests to display */
	history?: any;
}

/** Displays the current queue status */
export default function QueueDisplay({ history }: QueueDisplayProps): ReactElement {
	const { queueRes, queueError, startFetchingQStatus } = useQueueStatus(history);

	useEffect(() => {
		if (history) {
			startFetchingQStatus();
		}
	}, [history, startFetchingQStatus]);

	if (queueError) {
		return (
			<Typography color='error' mt={3} textAlign='center'>
				{queueError}
			</Typography>
		);
	}

	if (!queueRes) {
		return (
			<Stack alignItems='center' direction='row' justifyContent='space-between'>
				<Skeleton height={35} variant='text' width={100} />

				<Stack alignItems='center' direction='row' justifyContent='flex-end' spacing={2}>
					<Skeleton height={35} variant='text' width={100} />
					<Typography sx={{ display: 'flex' }} variant='body2'>
						<Skeleton variant='text' width={100} />
						<IconTooltip
							paragraphs={[
								'These numbers show how many models are processing in our app. A longer queue means your model will take more time to generate.',
							]}
						/>
					</Typography>
				</Stack>
			</Stack>
		);
	}

	// Render the response data
	return (
		<Stack alignItems='center' direction='row' justifyContent='space-between'>
			<Typography variant='body2'>3D Models currently in:</Typography>

			<Stack alignItems='center' direction='row' justifyContent='flex-end' spacing={2}>
				<Typography variant='body2'>Queue: {queueRes?.queueLength}</Typography>
				<Typography variant='body2'>
					Progress: {queueRes?.workersInProgress}
					<IconTooltip
						paragraphs={[
							'These numbers show how many models are processing in our app. A longer queue means your model will take more time to generate.',
						]}
					/>
				</Typography>
			</Stack>
		</Stack>
	);
}
